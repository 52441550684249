import React from 'react'
import { graphql } from "gatsby"

import Layout from '../layouts'
import SEO from '../components/elements/seo'

export default function WebFormTemplate({ data: { formsFormsYaml } }) {

  return (
    <Layout isSubMenuOpen={false}>

      <SEO
        title={`${formsFormsYaml.name} Form`}
        description={""}
        location={`/resources/forms/${formsFormsYaml.name.replaceAll(' ', '-').toLowerCase()}`}
        ogImg='/images/social-graph/the-claxton-bank.jpg'
      />

      <div css={{height: `90vh`, width: `100%`, overflow: `hidden`}}>
        <iframe src={formsFormsYaml.src} width="100%" height="100%" frameborder="0" style={{border: `0`, overflow: `hidden`, minHeight: `500px`, minWidth: `90vw`,}}></iframe>
      </div>
    </Layout>
  )
}
export const pageQuery = graphql`
  query webFormQuery($id: String) {
    formsFormsYaml(yamlId: { eq: $id }) {
      name
      src
    }
  }
`